<script>
import DashboardV1 from '../pages/Dashboard-v1.vue'
import DashboardV2 from '../pages/Dashboard-v2.vue'
import DashboardV3 from '../pages/Dashboard-v3.vue'
import EmailInbox from '../pages/Email-inbox.vue'
import EmailCompose from '../pages/Email-compose.vue'
import EmailDetail from '../pages/Email-detail.vue'
import Widgets from '../pages/Widgets.vue'
import UIGeneral from '../pages/UI-general.vue'
import UITypography from '../pages/UI-typography.vue'
import UITabsAccordion from '../pages/UI-tabs-accordion.vue'
import UIModalNotification from '../pages/UI-modal-notification.vue'
import UIWidgetBoxes from '../pages/UI-widget-boxes.vue'
import UIMediaObject from '../pages/UI-media-object.vue'
import UIButtons from '../pages/UI-buttons.vue'
import UIIcons from '../pages/UI-icons.vue'
import UISimpleLineIcons from '../pages/UI-simple-line-icons.vue'
import UIIonicons from '../pages/UI-ionicons.vue'
import UILanguageBarIcon from '../pages/UI-language-bar-icon.vue'
import UISocialButtons from '../pages/UI-social-buttons.vue'
import Bootstrap5 from '../pages/Bootstrap-5.vue'
import FormElements from '../pages/Form-elements.vue'
import FormPlugins from '../pages/Form-plugins.vue'
import FormWizards from '../pages/Form-wizards.vue'
import PosCounterCheckout from '../pages/Pos-counter-checkout.vue'
import PosCustomerOrder from '../pages/Pos-customer-order.vue'
import PosKitchenOrder from '../pages/Pos-kitchen-order.vue'
import PosMenuStock from '../pages/Pos-menu-stock.vue'
import PosTableBooking from '../pages/Pos-table-booking.vue'
import TableBasic from '../pages/Table-basic.vue'
import TableData from '../pages/Table-data.vue'
import ChartJs from '../pages/Chart-js.vue'
import ChartD3 from '../pages/Chart-d3.vue'
import ChartApex from '../pages/Chart-apex.vue'
import Calendar from '../pages/Calendar.vue'
import Map from '../pages/Map.vue'
import Gallery from '../pages/Gallery.vue'
import PageBlank from '../pages/Page-blank.vue'
import PageWithFooter from '../pages/Page-with-footer.vue'
import PageWithFixedFooter from '../pages/Page-with-fixed-footer.vue'
import PageWithoutSidebar from '../pages/Page-without-sidebar.vue'
import PageWithRightSidebar from '../pages/Page-with-right-sidebar.vue'
import PageWithMinifiedSidebar from '../pages/Page-with-minified-sidebar.vue'
import PageWithTwoSidebar from '../pages/Page-with-two-sidebar.vue'
import PageFullHeight from '../pages/Page-full-height.vue'
import PageWithWideSidebar from '../pages/Page-with-wide-sidebar.vue'
import PageWithLightSidebar from '../pages/Page-with-light-sidebar.vue'
import PageWithMegaMenu from '../pages/Page-with-mega-menu.vue'
import PageWithTopMenu from '../pages/Page-with-top-menu.vue'
import PageWithBoxedLayout from '../pages/Page-with-boxed-layout.vue'
import PageWithMixedMenu from '../pages/Page-with-mixed-menu.vue'
import PageBoxedLayoutWithMixedMenu from '../pages/Page-boxed-layout-with-mixed-menu.vue'
import PageWithTransparentSidebar from '../pages/Page-with-transparent-sidebar.vue'
import PageWithSearchSidebar from '../pages/Page-with-search-sidebar.vue'
import ExtraTimeline from '../pages/Extra-timeline.vue'
import ExtraComingSoon from '../pages/Extra-coming-soon.vue'
import ExtraSearch from '../pages/Extra-search.vue'
import ExtraInvoice from '../pages/Extra-invoice.vue'
import ExtraError from '../pages/Extra-error.vue'
import ExtraProfile from '../pages/Extra-profile.vue'
import ExtraScrumBoard from '../pages/Extra-scrum-board.vue'
import ExtraCookieAcceptanceBanner from '../pages/Extra-cookie-acceptance-banner.vue'

import AuthLogin from '../pages/auth/Login.vue'
import AuthRegister from '../pages/auth/Register.vue'
import AuthForgot from '../pages/auth/Forgot.vue'
import AuthReset from '../pages/auth/Reset.vue'

import UserLoginV1 from '../pages/User-login-v1.vue'
import UserLoginV2 from '../pages/User-login-v2.vue'
import UserLoginV3 from '../pages/User-login-v3.vue'
import UserRegisterV3 from '../pages/User-register-v3.vue'
import HelperCss from '../pages/Helper-css'


import ReporteVentas from '../pages/Reporte-ventas.vue'
import ReporteFaturacion from '../pages/Reporte-facturaciones.vue'
import ReporteCRE from '../pages/Reporte-cre.vue'
import ReporteGetNet from '../pages/Reportes-bancos-getnet.vue'
import ReporteNetPay from '../pages/Reportes-bancos-netpay.vue'
import ReporteBbva from '../pages/Reporte-bancos-bbvba.vue'
import ReporteTransaccionesDobles from '../pages/Reportes-bancos-transaccionesdobles.vue'



import TransaccionesApp from '../pages/Reportes-bancos-transacciones-app.vue'
import ReporteFajillas from '../pages/Reporte-fajillas.vue'
import BinarappGetPedidos from '../pages/binarapp-getpedidos.vue'


import ReporteAcuses from '../pages/Reporte-acuses.vue'
import ReporteAcusesEstacion from '../pages/Reporte-acuses-estacion.vue'
import ReporteFacturacionAnual from '../pages/reportes-facturacion-anual.vue'
import ReporteVentasEstacion from '../pages/Reporte-ventas-estacion.vue'
import ReporteResumen from '../pages/resumen.vue'
import ReporteCorresponsalia from '../pages/reporte-corresponsalia.vue'


import AutorizacionFajillas from '../pages/Autorizacion-fajillas.vue'
import EPagoTransaccionesCorte from '../pages/epago-transacciones-corte.vue'
import Tabulador from '../pages/tabulador.vue'
import ReporteCargarExcel from '../pages/Reportes-cargarexcel.vue'
import ReporteVentasApp from '../pages/Reporte-ventas-app.vue'
import ReporteMinitorTanques from '../pages/Reporte-monitor-tanques.vue'
import Laboratorio from '../pages/laboratorio.vue'
import ComprasGasoinfo from '../pages/compras-gasoinfo.vue'
import ComprasCG from '../pages/compras-cg.vue'
import PrepagoSmartcardCargarexcel from '../pages/prepago-smartcard-cargarexcel.vue'
import CalendarioHorarios from '../pages/calendario-horarios.vue'
import AsignarHorarios from '../pages/asignar-horarios.vue'
import CalificacionesClienteOculto from '../pages/CalificacionesClienteOculto.vue'
import ImagenEstacionExcelPorcentajes from '../pages/ImagenEstacionExcelPorcentajes.vue'

import ticketsAcomulador from '../pages/Reporte-TicketsAcomulador.vue'
import PuntosCanjeados from '../pages/Reporte-PuntosCanjeados.vue'


import AcusesPDF from '../pages/AcusesPDF.vue'



import ComparativoProductos from '../pages/comparativo-productos.vue'
import ComparativoTiposDePago from '../pages/comparativo-tiposdepago.vue'


import VentaProducto from '../pages/venta-producto.vue'


import RecepcionCompras from '../pages/RecepcionCompras.vue'

import ReporteFajillasDashboard from '../pages/Reporte-fajillas-dashboard.vue'

//facturacion
import FacturacionServiciosAdministrativos from '../pages/Facturacion-serviciosadministrativos.vue'
import FacturacionServiciosAdminConsulta from '../pages/Facturacion-serviciasadminconsulta.vue'

import LealtadEstratecaAcumular from '../pages/lealtad-estrateca-acumular.vue'
import LealtadEstratecaCanjeos from '../pages/lealtad-estrateca-canjeos.vue'

import FacturacionReporte from '../pages/Facturacion-reporte.vue'

import FacturacionRchClientes from '../pages/Facturacion-rch-clientes.vue'

//catalogos
import CatalogoClientes from '../pages/catalogo-clientes.vue'
import CatalogoClientesReadOnly from '../pages/catalogo-clientes.vue'
import CatalogoUsuarios from '../pages/catalogo-usuarios.vue'

import CatalogoEstacion from '../pages/catalogos-estaciones-estaciones.vue'



import CatalogosAutorizadores from '../pages/catalogos-colaboradores-autorizadores.vue'

import AsignarVendedoresBomba from '../pages/AsignarVendedoresBomba.vue'


import OperacionResumenCorte from '../pages/OperacionResumenCorte.vue'
import OperacionResumenCorteGrid from '../pages/OperacionResumenCorteGrid.vue'
import OperacionResumenCorteDetalle from '../pages/OperacionResumenCorteDetalle.vue'
import FacturasCuadreVentaEstacion from '../pages/FacturasCuadreVentaEstacion.vue'
import FacturasCuadreVenta from '../pages/FacturasCuadreVenta.vue'


import ReportesCorresponsaliaEstacion from '../pages/ReportesCorresponsaliaEstacion.vue'
import ReportesIngresoTipoPago from '../pages/IngresosPorTipoPago.vue'
import CorresponsaliaCuadre from '../pages/corresponsalia-cuadre.vue'
import CorresponsaliaCuadreDetalle from '../pages/corresponsalia-cuadre-detalle.vue'

import CorresponsaliaClonar from '../pages/corresponsalia-santader-clonar.vue';

import Jarreos from '../pages/jarreos.vue'
import DespachosHora from '../pages/despachos-hora.vue'
import TipoCambioEstaciones from '../pages/tipo-cambio-estaciones.vue'



import OperacionPreciosCombustibles from '../pages/Operaciones-precios-combustibles.vue'
import OperacionAplicarPrecios from '../pages/Operaciones-aplicar-precios.vue'


import OperacionComisionesEnvioVentas from '../pages/operacion-comisiones-envio-ventas.vue'
import OperacionComisionesReporte from '../pages/operacion-comisiones-reporte.vue'
import CorresponsaliaTicketsUSD from '../pages/Corresponsalia-ticketsUSD.vue'


import CorresponsaliaBanorteCargarExcel from '../pages/corresponsalia-banorte-cargaexcel.vue'

import ReportesComisionPorPlaza from '../pages/reportes-comision-por-plaza.vue'




const routes = [
  { path: '*', redirect: '/reportes/cre/xmlcre' },
  { path: '/dashboard/v1', component: DashboardV1 },
  { path: '/dashboard/v2', component: DashboardV2 },
  { path: '/dashboard/v3', component: DashboardV3 },
  { path: '/email/inbox', component: EmailInbox },
  { path: '/email/compose', component: EmailCompose },
  { path: '/email/detail', component: EmailDetail },
  { path: '/widgets', component: Widgets },
  { path: '/ui/general', component: UIGeneral },
  { path: '/ui/typography', component: UITypography },
  { path: '/ui/tabs-accordions', component: UITabsAccordion },
  { path: '/ui/modal-notification', component: UIModalNotification },
  { path: '/ui/widget-boxes', component: UIWidgetBoxes },
  { path: '/ui/media-object', component: UIMediaObject },
  { path: '/ui/buttons', component: UIButtons },
  { path: '/ui/icons', component: UIIcons },
  { path: '/ui/simple-line-icons', component: UISimpleLineIcons },
  { path: '/ui/ionicons', component: UIIonicons },
  { path: '/ui/language-bar-icon', component: UILanguageBarIcon },
  { path: '/ui/social-buttons', component: UISocialButtons },
  { path: '/bootstrap-5', component: Bootstrap5 },
  { path: '/form/elements', component: FormElements },
  { path: '/form/plugins', component: FormPlugins },
  { path: '/form/wizards', component: FormWizards },
  { path: '/pos/counter-checkout', component: PosCounterCheckout },
  { path: '/pos/customer-order', component: PosCustomerOrder },
  { path: '/pos/kitchen-order', component: PosKitchenOrder },
  { path: '/pos/menu-stock', component: PosMenuStock },
  { path: '/pos/table-booking', component: PosTableBooking },
  { path: '/table/basic', component: TableBasic },
  { path: '/table/data', component: TableData },




  /*
  
  
  
  */


  // { path: '/catalogos/clientes/asignarvendedoresbomba', component: AsignarVendedoresBomba },

  { path: '/reportes/Ventas/ventas', component: ReporteVentas },
  { path: '/reportes/Facturacion', component: ReporteFaturacion },
  { path: '/reportes/cre/xmlcre', component: ReporteCRE },
  { path: '/reportes/bancos/getnet', component: ReporteGetNet },
  { path: '/reportes/bancos/netpay', component: ReporteNetPay },
  { path: '/reportes/bancos/bbva', component: ReporteBbva },



  { path: '/reportes/bancos/transaccionesdobles', component: ReporteTransaccionesDobles },


  { path: '/reportes/bancos/transaccionesapp', component: TransaccionesApp },


  { path: '/reportes/fajillas/fajillas', component: ReporteFajillas },
  { path: '/operacion/compras/registrarcompra', component: BinarappGetPedidos },



  { path: '/precios/acuses/cargaracuses', component: AcusesPDF },
  { path: '/precios/acuses/consultar', component: ReporteAcuses },
  // { path: '/precios/acuses/', component: ReporteAcusesEstacion },



  { path: '/operacion/compras/registrarcompra2', component: RecepcionCompras },


  { path: '/reportes/fajillas/fajillasdashboard', component: ReporteFajillasDashboard },

  { path: '/reportes/facturacion/facturacionanual', component: ReporteFacturacionAnual },


  { path: '/operacion/anticipos/autorizarfajillas', component: AutorizacionFajillas },
  { path: '/operacion/corte/resumenbanco', component: EPagoTransaccionesCorte },

  { path: '/operacion/corte/tabulador', component: Tabulador },


  { path: '/operacion/anticipos/asignarbombas', component: AsignarVendedoresBomba },
  { path: '/operacion/corte/resumencorte1', component: OperacionResumenCorte },
  { path: '/operacion/corte/resumencorte2', component: OperacionResumenCorteGrid },
  { path: '/operacion/corte/resumencorte', component: OperacionResumenCorteDetalle },
  { path: '/reportes/facturacion/ventavsfacturaestacion/:fechaIn?/:fechaFin?/:ciudad?/:estacion?', component: FacturasCuadreVentaEstacion },
  { path: '/reportes/facturacion/ventavsfactura', component: FacturasCuadreVenta },


  { path: '/reportes/corresponsalia/corresponsaliaestacion', component: ReportesCorresponsaliaEstacion },
  { path: '/reportes/ingresos/ingresostipopago', component: ReportesIngresoTipoPago },
  { path: '/corresponsalia/santander/cuadre', component: CorresponsaliaCuadre },
  { path: '/corresponsalia/santander/cuadredetalle/:estacion?/:fecha?/:turno?/:ciudad?/:importecorte?', component: CorresponsaliaCuadreDetalle },

  { path: '/corresponsalia/santander/clonar', component: CorresponsaliaClonar },


  { path: '/reportes/jarreos/consultar', component: Jarreos },
  { path: '/reportes/ventas/despachosporhora', component: DespachosHora },


  { path: '/corresponsalia/tipodecambio/capturar', component: TipoCambioEstaciones },

  { path: '/operacion/comisiones/enviarventas', component: OperacionComisionesEnvioVentas },
  { path: '/operacion/comisiones/consultar', component: OperacionComisionesReporte },
  { path: '/corresponsalia/santander/generarcorresponsalia', component: CorresponsaliaTicketsUSD },

  { path: '/Corresponsalia/Banorte/CargarExcel', component: CorresponsaliaBanorteCargarExcel },



  { path: '/precios/precioscombustible/capturarprecios', component: OperacionPreciosCombustibles },
  { path: '/precios/precioscombustible/aplicarprecios', component: OperacionAplicarPrecios },


  { path: '/fiscal/estimulofiscal/generarxmlsat', component: ReporteCargarExcel },

  { path: '/reportes/ventas/ventasapp', component: ReporteVentasApp },
  { path: '/reportes/ventas/ventascgestaciones', component: ReporteVentasEstacion },
  { path: '/reportes/comisiones/comisionporplaza', component: ReportesComisionPorPlaza },


  // corresponsalia
  { path: '/corresponsalia/2016/generarcorresponsalia', component: ReporteResumen },
  { path: '/corresponsalia/2016/consultar', component: ReporteCorresponsalia },




  { path: '/tanques', component: ReporteMinitorTanques },
  { path: '/laboratorio', component: Laboratorio },

  { path: '/reportes/comisiones/comparativocgvsaddes', component: ComparativoProductos },
  { path: '/reportes/ingresos/comparativotiposdepago', component: ComparativoTiposDePago },
  { path: '/reportes/cre/gasoinfo', component: ComprasGasoinfo },
  { path: '/reportes/compras/comprasCG', component: ComprasCG },


  { path: '/reportes/lealtad/ticketsacumulados', component: ticketsAcomulador },
  { path: '/reportes/lealtad/Canjeados', component: PuntosCanjeados },


  { path: '/prepago/smartcard/cargarexcel', component: PrepagoSmartcardCargarexcel },
  { path: '/bi/cargardatos/clienteoculto', component: CalificacionesClienteOculto },
  { path: '/bi/cargardatos/imagenestacion', component: ImagenEstacionExcelPorcentajes },

  { path: '/calendariohorario', component: CalendarioHorarios },
  { path: '/operacion/asistencia/plantactico', component: AsignarHorarios },



  { path: '/operacion/comisiones/comparativovsventa/:fechaIn?/:fechaFin?/:ciudad?/:estacion?', component: VentaProducto },//parametros opcionales




  //facturacion
  { path: '/facturacion/facturacionrch/facturacionclientes', component: FacturacionRchClientes },
  { path: '/facturacion/serviciosadministrativos/facturaservadmin', component: FacturacionServiciosAdministrativos },
  { path: '/facturacion/serviciosadministrativos/consultarfacturasservadmin', component: FacturacionServiciosAdminConsulta },


  { path: '/facturacion/facturacionrch/consultar', component: FacturacionReporte },


  { path: '/facturacion/facturacionrch/cancelarfacturacliente', component: ReporteVentas },
  { path: '/facturacion/facturacionrch/cancelarfacturacliente', component: ReporteVentas },
  { path: '/facturacion/facturacionrch/cancelarfacturaglobal', component: ReporteVentas },
  { path: '/reportes/Ventas/ventas', component: ReporteVentas },

  //catalogos
  { path: '/catalogos/clientes/visualizarclientes', component: CatalogoClientesReadOnly },
  { path: '/catalogos/clientes/modificarclientes', component: CatalogoClientes },
  { path: '/catalogos/colaborador/colaboradores', component: CatalogoUsuarios },


  { path: '/catalogos/colaborador/autorizadores', component: CatalogosAutorizadores },

  { path: '/Catalogos/Estaciones/Estaciones', component: CatalogoEstacion },

  { path: '/facturacion/facturacionusfuel/facturacionclientes', component: CatalogoUsuarios },


  { path: '/lealtad/estrateca/acumular', component: LealtadEstratecaAcumular },
  { path: '/lealtad/estrateca/canjear', component: LealtadEstratecaCanjeos },



  { path: '/chart/js', component: ChartJs },
  { path: '/chart/d3', component: ChartD3 },
  { path: '/chart/apex', component: ChartApex },
  { path: '/calendar', component: Calendar },
  { path: '/map', component: Map },
  { path: '/page-option/blank', component: PageBlank },
  { path: '/page-option/with-footer', component: PageWithFooter },
  { path: '/page-option/with-fixed-footer', component: PageWithFixedFooter },
  { path: '/page-option/without-sidebar', component: PageWithoutSidebar },
  { path: '/page-option/with-right-sidebar', component: PageWithRightSidebar },
  { path: '/page-option/with-minified-sidebar', component: PageWithMinifiedSidebar },
  { path: '/page-option/with-two-sidebar', component: PageWithTwoSidebar },
  { path: '/page-option/full-height', component: PageFullHeight },
  { path: '/page-option/with-wide-sidebar', component: PageWithWideSidebar },
  { path: '/page-option/with-light-sidebar', component: PageWithLightSidebar },
  { path: '/page-option/with-mega-menu', component: PageWithMegaMenu },
  { path: '/page-option/with-top-menu', component: PageWithTopMenu },
  { path: '/page-option/with-boxed-layout', component: PageWithBoxedLayout },
  { path: '/page-option/with-mixed-menu', component: PageWithMixedMenu },
  { path: '/page-option/boxed-layout-with-mixed-menu', component: PageBoxedLayoutWithMixedMenu },
  { path: '/page-option/with-transparent-sidebar', component: PageWithTransparentSidebar },
  { path: '/page-option/with-search-sidebar', component: PageWithSearchSidebar },
  { path: '/gallery', component: Gallery },
  { path: '/extra/timeline', component: ExtraTimeline },
  { path: '/extra/coming-soon', component: ExtraComingSoon },
  { path: '/extra/search', component: ExtraSearch },
  { path: '/extra/invoice', component: ExtraInvoice },
  { path: '/extra/error', component: ExtraError },
  { path: '/extra/profile', component: ExtraProfile },
  { path: '/extra/scrum-board', component: ExtraScrumBoard },
  { path: '/extra/cookie-acceptance-banner', component: ExtraCookieAcceptanceBanner },

  { path: '/login', component: AuthLogin },
  { path: '/register', component: AuthRegister },
  { path: '/forgot', component: AuthForgot },
  { path: '/reset/:token', component: AuthReset },

  { path: '/user/login-v1', component: UserLoginV1 },
  { path: '/user/login-v2', component: UserLoginV2 },
  { path: '/user/login-v3', component: UserLoginV3 },
  { path: '/user/register-v3', component: UserRegisterV3 },
  { path: '/helper/css', component: HelperCss }
]

export default routes;
</script>
