import { render, staticRenderFns } from "./Reporte-bancos-bbvba.vue?vue&type=template&id=a6fe74fa&scoped=true&"
import script from "./Reporte-bancos-bbvba.vue?vue&type=script&lang=js&"
export * from "./Reporte-bancos-bbvba.vue?vue&type=script&lang=js&"
import style0 from "./Reporte-bancos-bbvba.vue?vue&type=style&index=0&id=a6fe74fa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6fe74fa",
  null
  
)

export default component.exports