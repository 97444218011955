<template>
    <div>
        <ol class="breadcrumb float-xl-end">
            <li class="breadcrumb-item"><a href="javascript:;">Lealtad</a></li>
            <li class="breadcrumb-item active">Estrateca</li>
            <li class="breadcrumb-item active">Acumular</li>
        </ol>

        <h1 class="page-header">
            Acumular
        </h1>
        <div class="row">
            <div class="col-xl-6">
                <panel title="Cliente" bodyClass="p-0">
                    <form class="form-horizontal form-control p-5">
                        <div class="form-group row">
                            <div class="col-md-5">
                                <label class="col-md col-form-label">Cardnumber</label>
                                <input class="form-control" type="number" v-model="Cardnumber">
                            </div>

                            <div class="col-md-5">
                                <label class="col-md col-form-label">Empresa</label>
                                <select class="form-select" name="" id="" v-model="EmpresaSeleccionada">
                                    <option :value="empresa" v-for="empresa in Empresas" :key="empresa">
                                        {{ empresa.Nombre }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-5">
                                <label class="col-md-2 col-form-label">ProgramID</label>
                                <input class="form-control" type="number" v-model="ProgramID" readonly>
                            </div>
                            <div class="col-md-5">
                                <label class="col-md-2 col-form-label">BrandID</label>
                                <input class="form-control" type="number" v-model="BrandID" readonly>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-10 btnBuscar">
                                <button type="button" class="btn btn-primary w-100px me-5px" @click="GetCardNumberInfo"
                                    :disabled="loading">
                                    <span v-if="loading">
                                        <i class="fas fa-spinner fa-spin"></i> Cargando...
                                    </span>
                                    <span v-else>Buscar</span>
                                </button>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-5">
                                <label class="col-md col-form-label">Cliente</label>
                                <input class="form-control text-center" type="text" v-model="MemberInfo.Cliente"
                                    readonly>
                            </div>

                            <div class="col-md-5">
                                <label class="col-md col-form-label">Puntos</label>
                                <input class="form-control text-center" type="number"
                                    v-model="MemberInfo.CurrentBalance" readonly>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-5">
                                <label class="col-md col-form-label">Productos</label>
                                <input class="form-control text-center" type="text" v-model="MemberInfo.Producto"
                                    readonly>
                            </div>

                            <div class="col-md-5">
                                <label class="col-md col-form-label">Litros Ganados</label>
                                <input class="form-control text-center" type="number" v-model="MemberInfo.ProductCredit"
                                    readonly>
                            </div>
                        </div>
                    </form>
                </panel>
            </div>

            <div class="col-xl-6">
                <panel title="Ticket" bodyClass="p-0">
                    <form class="form-horizontal form-control p-5">
                        <div class="form-group row">
                            <div class="col-md-5">
                                <label class="col-md-2 col-form-label">Estación</label>
                                <input class="form-control" type="number" v-model="Estacion">
                            </div>

                            <div class="col-md-5">
                                <label class="col-md-2 col-form-label">Ticket</label>
                                <input class="form-control" type="number" v-model="Transaccion">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-10 btnBuscar">
                                <button type="button" class="btn btn-primary w-100px me-5px" @click="GetGetTicketFull"
                                    :disabled="loading">
                                    <span v-if="loading">
                                        <i class="fas fa-spinner fa-spin"></i> Cargando...
                                    </span>
                                    <span v-else>Buscar</span>
                                </button>

                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-5">
                                <label class="col-md col-form-label">Forma de Pago</label>
                                <input class="form-control text-left" type="text" v-model="Ticket.TipoPago" readonly>
                            </div>
                            <div class="col-md-5">
                                <label class="col-md col-form-label">Transaccion</label>
                                <input class="form-control text-left" type="text" v-model="Ticket.Transaccion" readonly>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-5">
                                <label class="col-md col-form-label">Turno</label>
                                <input class="form-control text-left" type="text" v-model="Ticket.Turno" readonly>
                            </div>

                            <div class="col-md-5">
                                <label class="col-md col-form-label">Fecha</label>
                                <input class="form-control text-left" type="text" v-model="Ticket.Fecha" readonly>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-5">
                                <label class="col-md col-form-label">Hora</label>
                                <input class="form-control text-left" type="text" v-model="Ticket.Hora" readonly>
                            </div>
                            <div class="col-md-5">
                                <label class="col-md col-form-label">Bomba</label>
                                <input class="form-control text-left" type="text" v-model="Ticket.Bomba" readonly>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-5">
                            </div>
                            <div class="col-md-5">
                                <label class="col-md col-form-label">Total</label>
                                <input class="form-control text-left" type="text" v-model="Ticket.Total" readonly>
                            </div>
                        </div>
                        <div v-if="Ticket.PDF != undefined" class="form-group row">
                            <div class="col-md-5">
                                <button type="button" class="btn btn-primary w-100px me-5px"
                                    @click="openModalVisor(Ticket.PDF)" :disabled="loading">
                                    <span v-if="loading">
                                        <i class="fas fa-spinner fa-spin"></i> Cargando...
                                    </span>
                                    <span v-else>ver</span>
                                </button>

                            </div>

                            <div class="col-md-5 btnBuscar">
                                <button type="button" class="btn btn-success w-100px me-5px" @click="SetAccrualManual"
                                    :disabled="loading">
                                    <span v-if="loading">
                                        <i class="fas fa-spinner fa-spin"></i> Cargando...
                                    </span>
                                    <span v-else>Acumular</span>
                                </button>

                            </div>
                        </div>
                    </form>
                </panel>
            </div>
        </div>

        <b-modal id="modalDialogVisor" size="xl" centered cancel-variant="default">
            <template slot="modal-header">
                <h4 class="modal-title">Visualizando Ticket</h4>
                <a class="btn-close" @click="$bvModal.hide('modalDialogVisor')"></a>
            </template>
            <div class="card">
                <object type="application/pdf" :data="Archivo.base64" width="100%" height="600px"></object>
            </div>

            <template #modal-footer>
                <div class="row">
                    <div class="col">
                        <b-button class="float-right" variant="primary" @click="$bvModal.hide('modalDialogVisor')">
                            Close
                        </b-button>
                    </div>
                </div>
            </template>

        </b-modal>

        <!-- bottom left vue-notification -->
        <notifications group="top-right" position="top right" :speed="1000" />
    </div>
</template>

<style>
.btnBuscar {
    display: flex;
    justify-content: flex-end;
    /* Mueve el botón a la derecha */
    padding: 10px;
}
</style>

<script>
import axios from "axios";
export default {
    mounted: function () {
        //debugger; // eslint-disable-line no-debugger
        var userSetting = JSON.parse(localStorage.getItem('userSettings'));
        this.userSetting = userSetting;
        console.log(this.userSetting);
    },
    data() {
        return {
            loading: false,
            userSetting: undefined,
            EmpresaSeleccionada: null,
            Empresas: [
                {
                    Nombre: "RCH",
                    ProgramID: 53663,
                    BrandID: 53663
                },
                {
                    Nombre: "USF",
                    ProgramID: 52614,
                    BrandID: 52614
                }
            ],
            MemberInfo: {
                CardNumber: "",
                FirstName: "",
                LastNamePaternal: "",
                Cliente: "",
                CurrentBalance: 0.0,
                BalanceOnHold: 0.0,
                ProductCredit: 0.0,
                PurchaseSegment: "",
                Producto: "",
                UserExtID: ""
            },
            Cardnumber: "",
            ProgramID: "",
            BrandID: "",
            Empresa: "",
            Estacion: "",
            Transaccion: "",
            Ticket: {
                TipoPago: "",
                Transaccion: "",
                Turno: "",
                Fecha: "",
                Hora: "",
                Bomba: "",
                Total: "",
                PDF: undefined
            },
            Archivo: {
                base64: '',
            },
            pdfBase64Resend: undefined,
            colorpicker: {
                color1: "rgb(135,83,222,0.8)",
                color2: "rgb(52,143,226)",
            },
            sliderDefault: {
                value: 9,
                max: 25,
            },
            sliderLabel: {
                value: "2018-10-08",
                width: "80%",
                tooltip: "always",
                disabled: false,
                piecewise: true,
                piecewiseLabel: true,
                style: {
                    marginLeft: "10%",
                },
                data: ["2016-10-05", "2016-10-06", "2016-10-07"],
            },
            sliderInterval: {
                lazy: true,
                interval: 5,
                max: 25,
                piecewise: true,
            },
            sliderFormatter: {
                value: [0, 96],
                min: 0,
                max: 500,
                disabled: false,
                show: true,
                useKeyboard: true,
                tooltip: "always",
                formatter: "¥{value}",
                mergeFormatter: "¥{value1} ~ ¥{value2}",
            },
            sliderFixedRange: {
                value: [45, 65],
                min: 0,
                max: 100,
                interval: 1,
                fixed: true,
                processDragable: true,
            },
            tagsArray: ["tags1", "tags2"],
            date: new Date(),
            options: {
                format: "DD/MM/YYYY",
                useCurrent: false,
            },
            form: {
                time: null,
                startDate: null,
                endDate: null,
            },
            configs: {
                timePicker: {
                    format: "LT",
                    useCurrent: false,
                },
                start: {
                    format: "DD/MM/YYYY",
                    useCurrent: false,
                    showClear: true,
                    showClose: true,
                    minDate: new Date(),
                    maxDate: false,
                },
                end: {
                    format: "DD/MM/YYYY",
                    useCurrent: false,
                    showClear: true,
                    showClose: true,
                    minDate: new Date(),
                },
            },
        };
    },
    watch: {
        EmpresaSeleccionada(value) {
            console.log(value);
            this.ProgramID = value.ProgramID;
            this.BrandID = value.BrandID;
            this.Empresa = value.Nombre;
        },
    },
    methods: {

        limpiar() {
            this.Cardnumber = "";
            this.EmpresaSeleccionada = "";
            this.ProgramID = "";
            this.BrandID = "";
            this.Estacion = "";
            this.Transaccion = "";
            this.MemberInfo = {
                CardNumber: "",
                FirstName: "",
                LastNamePaternal: "",
                Cliente: "",
                CurrentBalance: 0.0,
                BalanceOnHold: 0.0,
                ProductCredit: 0.0,
                PurchaseSegment: "",
                Producto: "",
                UserExtID: ""
            };

            this.Ticket = {
                TipoPago: "",
                Transaccion: "",
                Turno: "",
                Fecha: "",
                Hora: "",
                Bomba: "",
                Total: "",
                PDF: undefined
            };
            this.Archivo = {
                base64: '',
            };
        },
        descargarPDF() {

            debugger; // eslint-disable-line no-debugger
            var self = this;
            var filename = "Factura_Electronica.pdf";
            // The actual download
            var link = document.createElement("a");
            //link.href = window.URL.createObjectURL(blob);
            link.href = "data:application/pdf;base64," + self.pdfBase64Resend;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        async GetCardNumberInfo() {
            this.loading = true;
            try {
                if (this.Cardnumber == '') {
                    this.alertMessage = "Debe ingresar el Cardnumber.";
                    this.alertTitle = "Error";
                    this.show("top-right", "error");
                    this.loading = false;
                    return;
                }

                if (this.Empresa == '') {
                    this.alertMessage = "Debe ingresar la empresa.";
                    this.alertTitle = "Error";
                    this.show("top-right", "error");
                    this.loading = false;
                    return;
                }

                const response = await axios.post("Lealtad/CardNumberInfo", {
                    Data: {
                        Cardnumber: this.Cardnumber,
                        ProgramId: this.ProgramID,
                        BrandId: this.BrandID,
                        Empresa: this.Empresa
                    }
                });
                if (response.data.Success) {
                    this.MemberInfo = response.data.response.MemberInfo[0];
                    this.MemberInfo.Cliente = this.MemberInfo.FirstName + ' ' + this.MemberInfo.LastNamePaternal;
                    console.log(this.MemberInfo);

                    switch (this.MemberInfo.PurchaseSegment) {
                        case '62':
                            this.MemberInfo.Producto = '87 Octanos';
                            break;

                        case '63':
                            this.MemberInfo.Producto = '91 Octanos';
                            break;
                        case '':
                            this.MemberInfo.Producto = '';
                            break;
                    }
                    this.loading = false;

                } else {
                    this.alertMessage = response.data.Message;
                    this.alertTitle = "Error";
                    this.show("top-right", "error");
                    this.loading = false;
                }
            } catch (e) {
                this.alertMessage = "Ocurrio un error";
                this.alertTitle = "Error";
                this.show("top-right", "error");
                this.loading = false;
            }
        },

        async GetGetTicketFull() {
            this.loading = true;
            try {

                if (this.Estacion == '') {
                    this.alertMessage = "Debe ingresar Estacion.";
                    this.alertTitle = "Error";
                    this.show("top-right", "error");
                    this.loading = false;
                    return;
                }

                if (this.Transaccion == '') {
                    this.alertMessage = "Debe ingresar el Ticket.";
                    this.alertTitle = "Error";
                    this.show("top-right", "error");
                    this.loading = false;
                    return;
                }

                const response = await axios.post("Despacho/GetTicketFull", {
                    Data: {
                        Estacion: this.Estacion,
                        Transaccion: this.Transaccion
                    }
                });
                if (response.data.Success) {
                    var data = response.data.response
                    this.Ticket = data;
                    //this.openModalVisor(data.PDF)
                    this.loading = false;
                } else {
                    this.alertMessage = response.data.Message;
                    this.alertTitle = "Error";
                    this.show("top-right", "error");
                    this.loading = false;
                }
            } catch (e) {
                this.alertMessage = "Ocurrio un error";
                this.alertTitle = "Error";
                this.show("top-right", "error");
                this.loading = false;
            }
        },

        async openModalVisor(data) {
            var self = this;

            self.loading = true;

            try {
                this.$bvModal.show('modalDialogVisor');

                self.Archivo.base64 = "data:application/pdf;base64," + data;
                self.loading = false;

            } catch (e) {
                self.loading = false;
                self.alertMessage = e;
                self.alertTitle = "Error";
                self.show('top-right', 'error');
            }
        },

        async SetAccrualManual() {
            this.loading = true;
            try {

                if (this.Cardnumber == '') {
                    this.alertMessage = "Debe ingresar el Cardnumber.";
                    this.alertTitle = "Error";
                    this.show("top-right", "error");
                    this.loading = false;
                    return;
                }

                if (this.Estacion == '') {
                    this.alertMessage = "Debe ingresar Estacion.";
                    this.alertTitle = "Error";
                    this.show("top-right", "error");
                    this.loading = false;
                    return;
                }

                if (this.Transaccion == '') {
                    this.alertMessage = "Debe ingresar el Ticket.";
                    this.alertTitle = "Error";
                    this.show("top-right", "error");
                    this.loading = false;
                    return;
                }

                if (this.ProgramID == '') {
                    this.alertMessage = "Debe ingresar el ProgramID.";
                    this.alertTitle = "Error";
                    this.show("top-right", "error");
                    this.loading = false;
                    return;
                }

                if (this.BrandID == '') {
                    this.alertMessage = "Debe ingresar el BrandID.";
                    this.alertTitle = "Error";
                    this.show("top-right", "error");
                    this.loading = false;
                    return;
                }

                if (this.Empresa == '') {
                    this.alertMessage = "Debe ingresar la empresa.";
                    this.alertTitle = "Error";
                    this.show("top-right", "error");
                    this.loading = false;
                    return;
                }

                var postData = {
                    Data: {

                        Cardnumber: this.Cardnumber,
                        Estacion: this.Estacion,
                        Transaccion: this.Ticket.Transaccion,
                        ProgramId: this.ProgramID,
                        BrandId: this.BrandID,
                        Empresa: this.Empresa,
                        Usuario: this.userSetting.Usuario
                    }
                }

                const response = await axios.post("Lealtad/AccrualManual",
                    postData
                );
                if (response.data.Success) {
                    var data = response.data.response;
                    this.alertMessage = response.data.Message;
                    this.alertTitle = "Correcto";
                    this.show("top-right", "Success");
                    this.limpiar();
                    this.loading = false;
                } else {
                    this.alertMessage = response.data.Message;
                    this.alertTitle = "Error";
                    this.show("top-right", "error");
                    this.limpiar();
                    this.loading = false;
                }
            } catch (e) {
                this.alertMessage = "Ocurrio un error";
                this.alertTitle = "Error";
                this.show("top-right", "error");
                this.loading = false;
            }
        },

        onStartChange(e) {
            this.$set(this.configs.end, "minDate", e.date || null);
        },
        onEndChange(e) {
            this.$set(this.configs.start, "maxDate", e.date || null);
        },
        show(group, type = '') {
            const text = this.alertMessage;
            this.$notify({
                group,
                title: this.alertTitle,
                text,
                type,
                data: {
                    randomNumber: Math.random()
                }
            })
        },
        clean(group) {
            this.$notify({ group, clean: true })
        },
        swalNotification(swalType) {
            var btnClass = (swalType == 'error') ? 'danger' : swalType;
            btnClass = (swalType == 'question') ? 'primary' : btnClass;
            this.$swal({
                title: 'Are you sure?',
                text: 'You will not be able to recover this imaginary file!',
                type: swalType,
                showCancelButton: true,
                buttonsStyling: false,
                confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
                cancelButtonText: 'Cancel',
                confirmButtonClass: 'btn me-5px btn-' + btnClass + '',
                cancelButtonClass: 'btn btn-default',
            })
        },
        showNotification(title, text, type) {
            this.alertTitle = title;
            this.alertMessage = text;
            this.show("top-right", type);
        },

    },
};
</script>