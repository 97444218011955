<template>
  <div>
    <div class="loading-search" v-if="loading">
      <div class="fa-3x text-center">
        <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
      </div>
    </div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Operaciones</a></li>
      <!-- <li class="breadcrumb-item"><a href="javascript:;">Fajillas</a></li> -->
      <li class="breadcrumb-item active"><a href="javascript:;">Precios Combustible</a></li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">Precios Combustible </h1>
    <!-- END page-header -->



    <form @submit.prevent="search">
      <div class="row">
        <div class="col-md-8">
          <div class="form-group row">

            <div class="col-md-3">
              <label class="col-form-label">Fecha</label>
              <div class="input-group">
                <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                <label for="dateTimeInput" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div>


            <!-- <div class="col-md-3">
                  <label class="col-form-label">Fecha final</label>
                <div class="input-group">
                  <date-picker id="dateTimeInputEnd" v-model="dateEnd" :config="options"></date-picker>
                  <label for="dateTimeInputEnd" class="input-group-text">
                    <i class="fa fa-calendar"></i>
                  </label>
                </div>
              </div> -->
            <div class="col-md-3">
              <label class="col-form-label">Ciudad</label>
              <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect"
                :disabled="deshabilitarCombos">
                <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                  {{ ciudad.Nombre }}
                </option>
              </select>
            </div>
            <!-- <div class="col-md-3">
                  <label class="col-form-label">Estación</label>
                  <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">
                    <option value="0">Todas</option>
                      <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                      {{ estacion.Nombre }}
                      </option>
                    </select>
              </div> -->


            <!-- <div class="col-md-6">
                <button type="submit" class="btn btn-success me-1 mb-1">{{textoBotonBuscar}}</button>
              </div> -->
          </div>
        </div>
        <div class="col-md-4">
          <!-- <button type="button" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;" v-on:click="obtenerPrecios()">Obtener Precios</button> -->
          <button type="submit" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">{{ textoBotonBuscar
            }}</button>
          <!-- <button type="button" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;" v-on:click="redirectAplicarPrecios()">Aplicar Precios</button> -->
        </div>
      </div>
    </form>

    <panel title="Precios Combustible" bodyClass="p-0" class="">
      <div>
        <div class="table-responsive">
          <table class="table table-striped mb-0 align-middle tabla-precios">
            <thead>
              <tr>
                <th rowspan="2" class="header-azul border-r">Vigente el "Fecha"</th>
                <th rowspan="2" class="header-azul border-r">HORA DE CAMBIO</th>
                <th colspan="5" class="header-mega border-r">MEGA</th>
                <th colspan="5" class="header-ultra border-r">ULTRA</th>
                <th colspan="5" class="header-diesel">DIESEL</th>
                <!-- <th width="1%"></th> -->
              </tr>
              <tr>
                <!--  -->
                <!--  -->
                <th class="header-azul">P.ACTUAL</th>
                <th class="header-azul">P.NUEVO</th>
                <th class="header-azul">DIFERENCIA</th>
                <th class="header-azul">CAMBIO</th>
                <th class="header-azul border-r"></th>

                <th class="header-azul">P.ACTUAL</th>
                <th class="header-azul">P.NUEVO</th>
                <th class="header-azul">DIFERENCIA</th>
                <th class="header-azul">CAMBIO</th>
                <th class="header-azul border-r"></th>

                <th class="header-azul">P.ACTUAL</th>
                <th class="header-azul">P.NUEVO</th>
                <th class="header-azul">DIFERENCIA</th>
                <th class="header-azul">CAMBIO</th>
                <th class="header-azul"></th>


                <!-- <th width="1%"></th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in preciosArray" v-bind:key="item.Estacion">
                <td class="border-r" style="text-align: left;">{{ item.Nombre }}</td>
                <td class="border-r">{{ item.Hora }}</td>

                <td>{{ item.PrecioActual87 | currency }}</td>
                <td>{{ item.PrecioNuevo87 | currency }}</td>
                <td>{{ item.Diferencia87 | currency }}</td>
                <td>{{ item.Cambio87 }}</td>
                <td class="border-r">
                  <button v-b-modal.modalDialog class="btn btn-xs btn-success"
                    style="font-size: 10px; line-height: 13px; padding: 1px 2px;"
                    @click="abrirModalPrecio(item, '87')">Cambiar</button>
                </td>

                <td>{{ item.PrecioActual91 | currency }}</td>
                <td>{{ item.PrecioNuevo91 | currency }}</td>
                <td>{{ item.Diferencia91 | currency }}</td>
                <td>{{ item.Cambio91 }}</td>
                <td class="border-r">
                  <button v-b-modal.modalDialog class="btn btn-xs btn-success"
                    style="font-size: 10px; line-height: 13px; padding: 1px 2px;"
                    @click="abrirModalPrecio(item, '91')">Cambiar</button>
                </td>

                <td><span v-if="item.IdDiesel != 0">{{ item.PrecioActualDiesel | currency }}</span></td>
                <td><span v-if="item.IdDiesel != 0">{{ item.PrecioNuevoDiesel | currency }}</span></td>
                <td><span v-if="item.IdDiesel != 0">{{ item.DiferenciaDiesel | currency }}</span></td>
                <td>{{ item.CambioDiesel }}</td>
                <td class="">
                  <button v-b-modal.modalDialog class="btn btn-xs btn-success"
                    style="font-size: 10px; line-height: 13px; padding: 1px 2px;"
                    @click="abrirModalPrecio(item, 'diesel')" :disabled="item.IdDiesel == 0">Cambiar</button>
                </td>



                <!-- <td nowrap>
											<a href="javascript:;" class="btn btn-sm btn-primary w-60px me-1">Edit</a>
											<a href="javascript:;" class="btn btn-sm btn-white w-60px">Delete</a>
										</td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </panel>




    <!-- #modal-dialog -->
    <b-modal id="modalDialog" size="lg">
      <template slot="modal-header">
        <h4 class="modal-title">Nuevo Precio - {{ precioSeleccionado.EstacionNombre }}</h4>
        <a class="btn-close" @click="$bvModal.hide('modalDialog')"></a>
      </template>

      <div>
        <form action="/" method="POST">
          <fieldset>
            <!-- <div class="mb-3">
                    <label class="form-label" for="txtHora">{{precioSeleccionado.Hora}}</label>
                </div> -->
            <div class="mb-3">
              <label class="form-label" for="txtPrecioActual">Precio Actual</label>
              <input class="form-control" type="number" step="any" id="txtPrecioActual" disabled
                placeholder="Precio Actual" v-model="precioSeleccionado.PrecioActual" />
            </div>
            <div class="mb-3">
              <label class="form-label" for="txtPrecioNuevo">Precio Nuevo</label>
              <input class="form-control" type="number" min=".01" step=".02" id="txtPrecioNuevo"
                placeholder="Precio Nuevo" @change="calcularCambioPrecio()" v-model="precioSeleccionado.PrecioNuevo" />
            </div>

            <div class="mb-3 row">
              <div class="col-md-6">
                <label class="form-label" for="txtPrecio">Fecha</label>
                <div class="input-group">
                  <date-picker id="dateTimeInputModal" v-model="modalDate" :config="options"></date-picker>
                  <label for="dateTimeInputModal" class="input-group-text">
                    <i class="fa fa-calendar"></i>
                  </label>
                </div>
              </div>

              <div class="col-md-6">
                <label class="form-label" for="txtPrecio">Hora</label>
                <div class="input-group date" id="datetimepicker2">
                  <date-picker id="timeInput" :config="configs.timePicker" v-model="form.time"
                    placeholder="Time"></date-picker>
                  <label for="timeInput" class="input-group-text">
                    <i class="fa fa-clock"></i>
                  </label>
                </div>
              </div>
            </div>

            <div class="mb-3">
              <label class="form-label" for="txtDiferencia">Diferencia</label>
              <input class="form-control" type="number" step="any" id="txtDiferencia" disabled placeholder="Diferencia"
                v-model="precioSeleccionado.Diferencia" />
            </div>
            <div class="mb-3">
              <label class="form-label" for="txtPrecio">Cambio</label>
              <input class="form-control" type="text" id="txtPrecio" placeholder="Precio" disabled
                v-model="precioSeleccionado.Cambio" />
            </div>


            <div class="mb-3">
              <label class="form-label" for="txtIVA">IVA</label>
              <input class="form-control" type="text" id="txtIVA" placeholder="IVA" disabled v-model="precioCG.IVA" />
            </div>
            <div class="mb-3">
              <label class="form-label" for="txtIEPS">IEPS</label>
              <input class="form-control" type="text" id="txtIEPS" placeholder="IEPS" disabled
                v-model="precioCG.IEPS" />
            </div>


            <div class="mb-3">
              <div class="table-responsive">
                <table class="table table-striped mb-0 align-middle">
                  <thead>
                    <tr>
                      <th>Fecha</th>
                      <th>Hora</th>
                      <th>Producto</th>
                      <th>PrecioActual</th>
                      <th>PrecioNuevo</th>
                      <th>Diferencia</th>
                      <th>Cambio</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="item in preciosModal" v-bind:key="item.Id">
                      <!-- <td>{{item.}}</td> -->
                      <td>
                        <label v-html='convertirFecha(item.Fecha)'></label>
                        <!-- {{item.Fecha}} -->
                      </td>
                      <td>{{ item.Hora }}</td>
                      <td>{{ item.Producto }}</td>
                      <td>{{ item.PrecioActual | currency }}</td>
                      <td>{{ item.PrecioNuevo | currency }}</td>
                      <td>
                        <label v-html='convertirDecimal(item.Diferencia)'></label>
                        <!-- {{item.Diferencia}} -->
                      </td>
                      <td>{{ item.Cambio }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </fieldset>
        </form>
      </div>

      <template slot="modal-footer">
        <button type="button" class="btn btn-success mr-auto" @click="guardarPrecioNuevo()">{{ textoBotonGuardar
          }}</button>
        <button type="button" class="btn btn-secondary" @click="$bvModal.hide('modalDialog')">Cancelar</button>
      </template>
    </b-modal>



    <!-- #modal-dialog -->
    <b-modal id="modalDialogErrores" size="lg">
      <template slot="modal-header">
        <h4 class="modal-title">Errores</h4>
        <a class="btn-close" @click="$bvModal.hide('modalDialogErrores')"></a>
      </template>

      <div class="mb-3">
        <div class="table-responsive">
          <table class="table table-striped mb-0 align-middle">
            <thead>
              <tr>
                <th>Estacion</th>
                <th>Producto</th>
                <th>Mensaje</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in errores" v-bind:key="index">
                <td>{{ item.Estacion }}</td>
                <td>{{ item.Producto }}</td>
                <td>{{ item.Mensaje }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <template slot="modal-footer">
        <button type="button" class="btn btn-secondary" @click="$bvModal.hide('modalDialogErrores')">Cerrar</button>
      </template>
    </b-modal>

    <!-- bottom left vue-notification -->
    <notifications group="top-right" position="top right" :speed="1000" />
  </div>
</template>

<script>

import axios from "axios";
import moment from 'moment';
var numeral = require("numeral");
import Vue from "vue";
Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,00.00"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("currency", function (value) {
  return numeral(value).format("$0,0.00");
});
export default {
  async created() {

  },
  mounted: function () {
    debugger; // eslint-disable-line no-debugger
    var userSetting = JSON.parse(localStorage.getItem('userSettings'));
    this.usuario = userSetting.Usuario;
    this.idUsuario = userSetting.NumeroEmpleado;
    this.nombreLogueado = userSetting.Nombre;
    this.usuarioEstacion = userSetting.Estacion;
    this.IdRol = userSetting.IdRol;
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;
    this.getEstaciones();
    this.estacionSelect = userSetting.Estacion;
    this.guardarPrecio.Usuario = userSetting.Usuario;
    if (this.IdRol == 3 || this.IdRol == 7) {
      this.deshabilitarCombos = true;
    }




  },
  methods: {
    convertirFecha(fecha) {
      // moment.locale('es');
      var fechaFormato = moment(fecha).format("DD/MM/YYYY");
      return fechaFormato;
    },
    convertirDecimal(numero) {
      var numeroFormato = numero.toFixed(2);
      return numeroFormato;
    },
    redirectAplicarPrecios() {
      this.$router.push({ path: '/precios/precioscombustible/aplicarprecios' });


    },
    calcularCambioPrecio() {
      var self = this;
      self.precioSeleccionado.Diferencia = self.precioSeleccionado.PrecioNuevo - self.precioSeleccionado.PrecioActual;
      self.precioSeleccionado.Diferencia = self.precioSeleccionado.Diferencia.toFixed(2);
      if (self.precioSeleccionado.Diferencia == 0) {
        self.precioSeleccionado.Cambio = "SIN CAMBIO";
      } else if (self.precioSeleccionado.Diferencia > 0) {
        self.precioSeleccionado.Cambio = "AUMENTA";
      } else if (self.precioSeleccionado.Diferencia < 0) {
        self.precioSeleccionado.Cambio = "BAJA";
      }
    },
    async abrirModalPrecio(item, tipo) {
      debugger; // eslint-disable-line no-debugger
      console.log(item);
      var self = this;
      self.form.time = "06:00:00";
      self.precioSeleccionado.Estacion = item.Estacion;
      self.precioSeleccionado.EstacionNombre = item.Nombre;
      self.precioSeleccionado.Fecha = self.intDate;
      self.precioSeleccionado.Hora = "06:00:00";//moment().format("HH:mm:ss");
      self.precioSeleccionado.Cambio = "SIN CAMBIO";
      self.precioSeleccionado.Usuario = self.usuario;
      self.precioSeleccionado.Diferencia = 0;
      self.modalDate = moment(self.date).add(0, 'days').format("YYYY/MM/DD");

      switch (tipo) {
        case '87':
          self.precioSeleccionado.CodigoProducto = item.CodigoProducto87;
          self.precioSeleccionado.PrecioActual = item.PrecioNuevo87;
          self.precioSeleccionado.PrecioNuevo = item.PrecioNuevo87;
          // self.precioSeleccionado.Producto = item.Producto;//no está en api/Precio/GetPrecioCambios
          break;

        case '91':
          self.precioSeleccionado.CodigoProducto = item.CodigoProducto91;
          self.precioSeleccionado.PrecioActual = item.PrecioNuevo91;
          self.precioSeleccionado.PrecioNuevo = item.PrecioNuevo91;
          // self.precioSeleccionado.Producto = item.Producto;//no está en api/Precio/GetPrecioCambios
          break;
        case 'diesel':
          self.precioSeleccionado.CodigoProducto = item.CodigoProductoDiesel;
          self.precioSeleccionado.PrecioActual = item.PrecioNuevoDiesel;
          self.precioSeleccionado.PrecioNuevo = item.PrecioNuevoDiesel;
          // self.precioSeleccionado.Producto = item.Producto;//no está en api/Precio/GetPrecioCambios
          break;
      }

      //self.precioSeleccionado.Diferencia = Number(self.precioSeleccionado.PrecioNuevo - self.precioSeleccionado.PrecioActual).toFixed(2);

      /*
              "Estacion":0,
              "Gasolinera":0,
              "CodigoProducto":0,
              "Producto":"",
              "Usuario":"",
              "TasaIVA":0,
              "CuotaIEPS":0
      
      
      
              "Fecha": "", 
              "Hora": "",
              "PrecioActual":0,
              "PrecioNuevo":0,
              "Cambio":"",
              "Diferencia":"",
      */

      debugger; // eslint-disable-line no-debugger

      self.guardarPrecio.Gasolinera = item.Gasolinera;
      self.guardarPrecio.CodigoProducto = self.precioSeleccionado.CodigoProducto;
      self.guardarPrecio.Producto = self.precioSeleccionado.Producto;
      self.guardarPrecio.Estacion = item.Estacion;

      self.guardarPrecio.TasaIVA = self.precioCG.IVA;
      self.guardarPrecio.CuotaIEPS = self.precioCG.IEPS;

      self.getPrecioCG(item);
      // call Precio/GetPreciosEstacion

      var dataToSend = {
        "Fecha": self.intDate,//"20220617", 
        "Estacion": item.Estacion,
        "Producto": self.precioSeleccionado.CodigoProducto,

      };
      self.getPreciosEstacion(dataToSend);

    },
    async getPreciosEstacion(dataToSend) {
      var self = this;
      try {
        debugger; // eslint-disable-line no-debugger
        const response = await axios.post('Precio/GetPreciosEstacion', { "Data": dataToSend });

        debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
          self.preciosModal = response.data.response;
        } else {
          if (response.data.Error != "OK") {
            self.alertMessage = response.data.Message;
            self.alertTitle = "Error";
            self.show('top-right', 'info');

          }
          self.preciosModal = [];
        }

        self.textoBotonBuscar = "Buscar";
        self.loading = false;
      } catch (e) {
        self.loading = false;
      }


    },
    async getPrecioCG(item) {
      var self = this;
      // call Precio/GetPrecioCG
      // PrecioNuevo
      // Diferencia
      // Cambio
      try {
        debugger; // eslint-disable-line no-debugger
        const response = await axios.post('Precio/GetPrecioCG', {
          "Data": {
            "Fecha": self.intDate,
            "Gasolinera": item.Gasolinera,
            "Producto": self.precioSeleccionado.CodigoProducto,

          }
        });

        debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
          self.precioCG = response.data.response;
          self.precioCG.PrecioNuevo = self.precioCG.Precio;
          self.precioCG.Diferencia = 0;
          self.precioCG.Cambio = "SIN CAMBIO";

        } else {
          self.alertMessage = response.data.Message;
          self.alertTitle = "Error";
          self.show('top-right', 'info');
          self.preciosModal = {};
        }

        self.textoBotonBuscar = "Buscar";
        self.loading = false;
      } catch (e) {
        self.loading = false;
        self.preciosModal = [];
      }

    },

    async guardarPrecioNuevo() {

      debugger; // eslint-disable-line no-debugger
      var self = this;
      self.loading = true;
      // self.textoBotonBuscar = "Buscando...";
      try {

        self.precioSeleccionado.Hora = moment(self.form.time, ["h:mm A"]).format("HH:mm:ss");
        debugger; // eslint-disable-line no-debugger
        if (self.precioSeleccionado.Hora == "Invalid date") {

          self.alertMessage = "Seleccione una hora valida"
          self.alertTitle = "Error";
          self.show('top-right', 'error');
          return;
        }

        self.intDateModal = self.modalDate.replace('/', '');
        self.intDateModal = self.intDateModal.replace('/', '');

        self.precioSeleccionado.Fecha = self.intDateModal;



        // "Fecha": "", 
        // "Hora": "",
        // "PrecioActual":0,
        // "PrecioNuevo":0,
        // "Cambio":"",


        var dataSend = {
          "Estacion": self.guardarPrecio.Estacion,
          "Gasolinera": self.guardarPrecio.Gasolinera,
          "CodigoProducto": self.guardarPrecio.CodigoProducto,
          "Producto": "",
          "FechaInicio": self.intDateModal,
          "Precio": self.precioSeleccionado.PrecioNuevo,
          "TasaIVA": self.precioCG.IVA,
          "CuotaIEPS": self.precioCG.IEPS,
          "Fecha": self.intDateModal,
          "Hora": self.precioSeleccionado.Hora,
          "PrecioActual": self.precioSeleccionado.PrecioActual,
          "PrecioNuevo": self.precioSeleccionado.PrecioNuevo,
          "Cambio": self.precioSeleccionado.Cambio,
          "Diferencia": self.precioSeleccionado.Diferencia,
          "Usuario": self.guardarPrecio.Usuario
        };

        debugger; // eslint-disable-line no-debugger
        const response = await axios.post('Precio/SetPrecio', { "Data": dataSend });

        debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
          self.getData();

          debugger; // eslint-disable-line no-debugger


          var dataTocall = {
            "Fecha": self.intDate,//"20220617", 
            "Estacion": self.guardarPrecio.Estacion,
            "Producto": self.precioSeleccionado.CodigoProducto,

          };

          //cerrar modal
          self.$bvModal.hide("modalDialog");

          self.getPreciosEstacion(dataTocall);

          self.alertMessage = response.data.Message;
          self.alertTitle = "Correcto";
          self.show('top-right', 'success');
        } else {
          console.log("error")
          self.alertMessage = response.data.Message;
          self.alertTitle = "Error";
          self.show('top-right', 'error');
        }

        // self.textoBotonBuscar = "Buscar";
        self.loading = false;
      } catch (e) {
        self.loading = false;
      }


    },
    async getCiudades() {
      try {
        const response = await axios.get('Catalogos/GetCiudades');
        //debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.ciudades = response.data.response;
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async getEstaciones() {
      try {
        debugger; // eslint-disable-line no-debugger
        const response = await axios.post('Estacion/GetEstaciones?ciudad=' + this.ciudadSelect);
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;
        this.productos = [];
        this.getProductos();
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    // separarListasPorProducto(productosArray){
    //   for(var n = 0 ; n < productosArray.length ; n++){
    //     if(productosArray[n])
    //   }
    // },
    show(group, type = '') {
      const text = this.alertMessage;
      this.$notify({
        group,
        title: this.alertTitle,
        text,
        type,
        data: {
          randomNumber: Math.random()
        }
      })
    },
    clean(group) {
      this.$notify({ group, clean: true })
    },
    swalNotification(swalType) {
      var btnClass = (swalType == 'error') ? 'danger' : swalType;
      btnClass = (swalType == 'question') ? 'primary' : btnClass;
      this.$swal({
        title: 'Are you sure?',
        text: 'You will not be able to recover this imaginary file!',
        type: swalType,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
        cancelButtonText: 'Cancel',
        confirmButtonClass: 'btn me-5px btn-' + btnClass + '',
        cancelButtonClass: 'btn btn-default',
      })
    },
    async obtenerPrecios() {

      debugger; // eslint-disable-line no-debugger
      var self = this;
      self.loading = true;
      self.textoBotonBuscar = "Buscando...";
      try {
        self.intDate = self.date.replace('/', '');
        self.intDate = self.intDate.replace('/', '');


        var ciudad = self.ciudadSelect != undefined ? self.ciudadSelect : 0;
        //var estacion = self.estacionSelect!=undefined?self.estacionSelect:0;

        const response = await axios.post('Precio/SetPreciosGateway', {
          "Data": {
            "Fecha": self.intDate,
            "Ciudad": ciudad,
            "Usuario": self.usuario
          }
        });

        debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
          self.getDataRefresh();
          self.alertTitle = "Correcto";
          self.alertMessage = "Los precios se guardaron exitosamente";
          var errores = response.data.response;

          if (errores.length !== null && errores.length > 0) {
            self.errores = errores;
            self.$bvModal.show('modalDialogErrores');
          }
          debugger; // eslint-disable-line no-debugger
          self.show('top-right', 'success');
        } else {

          self.alertMessage = response.data.Message;
          self.alertTitle = "Error";
          self.show('top-right', 'error');
        }

        // self.preciosArray = response.data.response;
        self.textoBotonBuscar = "Buscar";
        self.loading = false;
      } catch (e) {
        self.loading = false;
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }

    },
    async getDataRefresh() {

      debugger; // eslint-disable-line no-debugger
      var self = this;
      self.loading = true;
      self.textoBotonBuscar = "Buscando...";
      self.preciosArray = [];
      try {
        self.intDate = self.date.replace('/', '');
        self.intDate = self.intDate.replace('/', '');


        var ciudad = self.ciudadSelect != undefined ? self.ciudadSelect : 0;
        //var estacion = self.estacionSelect!=undefined?self.estacionSelect:0;

        const response = await axios.post('Precio/GetPrecioCambios', {
          "Data": {
            "Fecha": self.intDate,
            "Ciudad": ciudad
          }
        });

        debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
          self.preciosArray = response.data.response;
          self.loading = false;
        }

        self.textoBotonBuscar = "Buscar";
      } catch (e) {
        self.loading = false;

      }

    },
    async getData() {

      debugger; // eslint-disable-line no-debugger
      var self = this;
      self.loading = true;
      self.textoBotonBuscar = "Buscando...";
      self.preciosArray = [];
      try {
        self.intDate = self.date.replace('/', '');
        self.intDate = self.intDate.replace('/', '');


        var ciudad = self.ciudadSelect != undefined ? self.ciudadSelect : 0;
        //var estacion = self.estacionSelect!=undefined?self.estacionSelect:0;

        const response = await axios.post('Precio/GetPrecioCambios', {
          "Data": {
            "Fecha": self.intDate,
            "Ciudad": ciudad
          }
        });

        debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {

          self.preciosArray = response.data.response;
          self.loading = false;


        } else {
          self.obtenerPrecios();

        }

        self.textoBotonBuscar = "Buscar";
      } catch (e) {
        self.loading = false;
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }

    },
    async search(e) {
      e.preventDefault();
      this.getData();
    },
  },
  data() {
    return {
      guardarPrecio: {
        "Estacion": 0,
        "Gasolinera": 0,
        "Fecha": "",
        "Hora": "",
        "CodigoProducto": 0,
        "Producto": "",
        "PrecioActual": 0,
        "PrecioNuevo": 0,
        "Diferencia": "",
        "Cambio": "",
        "Usuario": "",
        "TasaIVA": 0,
        "CuotaIEPS": 0
      },
      intDateModal: "",
      preciosModal: [],
      configs: {
        timePicker: {
          format: 'LT',
          useCurrent: false
        },
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: new Date(),
          maxDate: false
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: new Date()
        }
      },
      form: {
        time: "06:00:00",
        startDate: null,
        endDate: null
      },
      modalDate: "",
      precioCG: {
        IVA: 0,
        IEPS: 0
      },
      precioSeleccionado: {
        Cambio: '',
        Diferencia: 0,
        PrecioActual: 0,
        PrecioNuevo: 0,
        Hora: ''
      },
      precioNuevoModal: 0,
      loading: false,
      nombreLogueado: "",
      idUsuario: undefined,
      preciosArray: [],
      deshabilitarCombos: false,
      ciudades: [],
      estaciones: [],
      ciudadSelect: 1,
      estacionSelect: 0,
      ventaDetalle: [],
      emailReenviar: "",
      uuidReenviar: "",
      date: moment().format("YYYY/MM/DD"),
      intDateEnd: "",
      dateEnd: moment().format("YYYY/MM/DD"),
      intDate: "",
      options: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      puestoSelected: {
        puesto: 0
      },
      rolSelected: {
        rol: 0
      },
      actualizarNIP: false,
      puestosSelect: [],
      rolesSelect: [],
      nuevoCorreo: '',
      clienteNuevo: true,
      clienteModal: {},
      textoBotonGuardar: "Guardar",
      textoBotonBuscar: "Buscar",
      clienteBuscar: "",
    };
  },
};
</script>
<style scoped>
.tabla-precios th {
  vertical-align: middle;
  text-align: center;
  color: white;
  font-size: 12px;
  /* line-height: .8; */
  padding: 3px 0 !important;
}

.tabla-precios td {
  text-align: center;
  font-size: 12px;
  /* line-height: .8; */
  padding: 3px 0 !important;
}

.border-r {
  border-right: 4px solid white;

}

.header-azul {
  background: navy;
  font-size: 10px !important;
  text-align: center;
}

.header-mega {
  background: deeppink;
}

.header-ultra {
  background: mediumpurple;
}

.header-diesel {
  background: limegreen;
}

.dx-theme-generic-typography {
  font-size: 12px !important;
}

.loading-search {
  position: absolute;
  width: 100%;
  left: 0px;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding-top: 150px;
  top: 0;
}
</style>